import { logger } from '@getvim/vim-connect-logger';
import { useVimUserMetadata } from '@getvim/internal-vim-os-sdk/react';
import { useEffect } from 'react';
import { eligibilityLogger } from '../utils/logger';

/**
 * enrich the shared logger with metadata
 */
export const useEnrichLoggerMetadata = (): void => {
  const { organization, ehrVendor, deviceId, user, userSessionId } = useVimUserMetadata();

  useEffect(() => {
    try {
      eligibilityLogger.info('Setting logger metadata', {
        organizationId: organization?.identifiers?.id,
        organizationName: organization?.identifiers?.name,
        ehrUserName: user?.identifiers?.ehrUsername,
        deviceId,
        userSessionId,
        noPHI: true,
      });
      logger.setMetadata({
        deviceId: deviceId || '',
        adapterName: ehrVendor,
        organizationId: organization?.identifiers?.id,
        organization_name: organization?.identifiers?.name,
        version: window?.$vim_environment?.version || '',
        windowId: '',
        ehrUserName: user?.identifiers?.ehrUsername,
      });
    } catch (error) {
      eligibilityLogger.warning('Error setting logger metadata', { error, noPHI: true });
    }
  }, [organization, ehrVendor, deviceId, userSessionId, user]);
};
