import { createContext } from 'react';

export interface GlobalContextProps {
  featureFlags: {
    shouldUseSourceVaultTokens: boolean;
    shouldUseSourceVaultTokensLoading: boolean;
    shouldDisplayLogo: boolean;
  };
}
export const GlobalContext = createContext<GlobalContextProps>({
  featureFlags: {
    shouldUseSourceVaultTokens: false,
    shouldUseSourceVaultTokensLoading: true,
    shouldDisplayLogo: false,
  },
});
