import { useVimOsMigrationAPI } from '@getvim-core-apps/vim-os-migration-api';
import {
  extractPatientSourceIdentifiers,
  extractPatientSourceVaultToken,
} from '@getvim-core-apps/organizations';
import { ApplicationTitle, Loader } from '@getvim/atomic-ui';
import { BaseWidgetAnalyticsEventTypes, useScrollHandler } from '@getvim/react-app-infra';
import { Entities } from '@getvim/vim-connect';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { widgetId } from '../consts';
import { useEligibility } from '../hooks/eligibility';
import { DetailsCardType } from '../utils/analytics';
import { EligibilityMain } from './templates';
import { shouldShowNetworkCard, ExportPdfMenu } from './organisms';

import { EligibilityPdfTemplate } from './templates/PdfTemplate';
import { ExportAnalyticsEventNames, ExportMenuOption } from '../types/export-types';
import { AppPatient, EligibilitySections } from '../types';

import './EligibilityContainer.less';
import { GlobalContext } from '../context';
import { analyticsManager } from '../utils/analyticManager';

interface EligibilityContainerProps {
  patient: AppPatient;
}

const onScrollHandle = () => {
  analyticsManager.track(BaseWidgetAnalyticsEventTypes.appScrollStart, {
    app_name: widgetId,
  });
};

const EligibilityContainer: React.FC<EligibilityContainerProps> = ({
  patient,
}: EligibilityContainerProps) => {
  const {
    config,
    platformActions: { modifyWidget },
  } = useVimOsMigrationAPI();

  const {
    featureFlags: { shouldUseSourceVaultTokens, shouldDisplayLogo },
  } = useContext(GlobalContext);
  const [sections, setSections] = useState<EligibilitySections>({
    memberAndEligibility: false,
    inNetworkDetails: false,
    outOfNetworkDetails: false,
  });

  const { eligibility, logo, executionTime, loading } = useEligibility(patient);

  const sendEligibilityAppEnabledAnalytic = useCallback(() => {
    if (eligibility) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const items_received: DetailsCardType[] = [DetailsCardType.MemberDetails];

      const { inNetwork, outOfNetwork } = eligibility;

      if (shouldShowNetworkCard(inNetwork)) {
        items_received.push(DetailsCardType.InNetworkDeductibles);
      }
      if (shouldShowNetworkCard(outOfNetwork)) {
        items_received.push(DetailsCardType.OutOfNetworkDeductibles);
      }

      const contentSupplierId = shouldUseSourceVaultTokens
        ? extractPatientSourceVaultToken(patient.patientSourceVaultTokens, eligibility.dataSource!)
            ?.sourceVaultToken
        : extractPatientSourceIdentifiers(
            patient.contentSupplierIdentifiers,
            eligibility.dataSource!,
          )?.contentSupplierId;

      analyticsManager.track('eligibility_app_enabled', {
        content_supplier_patient_id: contentSupplierId,
        app_source_enabled: config.contentSources ? config.contentSources : [],
        app_source_displayed: eligibility.dataSource ? [eligibility.dataSource] : undefined,
        items_received,
        execution_time: executionTime,
      });
    }
  }, [
    eligibility,
    config,
    executionTime,
    patient?.contentSupplierIdentifiers,
    patient.vimPatientId,
    patient?.patientSourceVaultTokens,
    shouldUseSourceVaultTokens,
  ]);

  useEffect(() => {
    modifyWidget({
      notifications: 0,
      disabled: !eligibility,
      loading,
      loaded: !loading,
      reason: loading ? undefined : Entities.UIElements.ModifyReason.Response,
    }).then(() => {
      if (eligibility && !loading) {
        sendEligibilityAppEnabledAnalytic();
      }
    });
  }, [eligibility, loading, modifyWidget, patient, sendEligibilityAppEnabledAnalytic]);

  useScrollHandler(onScrollHandle, window);

  useEffect(() => {
    const beforePrintHandler = () => {};

    const afterPrintHandler = () => {
      analyticsManager.track(ExportAnalyticsEventNames.EXPORT_MENU_ACTION_COMPLETED, {
        app_name: widgetId,
        export_option: ExportMenuOption.DOWNLOAD,
        status: 'successful',
        app_source_displayed: eligibility?.dataSource,
      });
    };

    window.addEventListener('beforeprint', beforePrintHandler);
    window.addEventListener('afterprint', afterPrintHandler);

    return () => {
      window.removeEventListener('beforeprint', beforePrintHandler);
      window.removeEventListener('afterprint', afterPrintHandler);
    };
  }, []);

  const onConfirm = (sectionsToSelect: EligibilitySections) => {
    setSections(sectionsToSelect);
  };

  useEffect(() => {
    if (Object.values(sections).some((section) => section)) {
      try {
        window.print();
      } catch {
        analyticsManager.track(ExportAnalyticsEventNames.EXPORT_MENU_ACTION_COMPLETED, {
          app_name: widgetId,
          export_option: ExportMenuOption.DOWNLOAD,
          status: 'failed',
          app_source_displayed: eligibility?.dataSource,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sections]);

  return (
    <div className="eligibility-container">
      {!loading ? (
        <ApplicationTitle title="Eligibility" logoUrl={shouldDisplayLogo ? logo?.url : undefined}>
          {eligibility?.memberId && <ExportPdfMenu eligibility={eligibility} onClick={onConfirm} />}
        </ApplicationTitle>
      ) : (
        <ApplicationTitle title="Eligibility" />
      )}
      {loading ? (
        <Loader color="grey" size="small" type="dots" />
      ) : (
        <>
          <EligibilityMain eligibility={eligibility} patient={patient} />
          {eligibility?.memberId ? (
            <div className="pdf-template-div">
              <EligibilityPdfTemplate
                eligibility={eligibility}
                sectionsToSelect={sections}
                insurer={patient?.insurance?.insurer}
              />
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};

export default EligibilityContainer;
